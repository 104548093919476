import { INTERNAL_LINK_WITHOUT_QUERY_OR_HASH } from '@zyro-inc/site-modules/constants/regex';

export const capitalizeFirstLetter = (string) => {
	if (typeof string !== 'string') {
		return '';
	}

	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getExtension = (path) => {
	const basename = path.split(/[/\\]/).pop();
	const position = basename.lastIndexOf('.');

	if (basename === '' || position < 1) {
		return '';
	}

	return basename.slice(position + 1).toLowerCase();
};

export const getFileNameFromURL = (url) => url.replace(/^.*[/\\]/, '').split('?')[0];

export const getInternalLinkWithoutQueryOrHash = (href) => href?.match(INTERNAL_LINK_WITHOUT_QUERY_OR_HASH)?.[0];

/**
 * @example
 * getBiggestIncrementedString({
 *   stringToMatch: 'string',
 *   strings: ['string', 'string10', 'string2']
 * }) // returns 'string11'
 */
export const getBiggestIncrementedString = ({
	stringToMatch,
	strings,
}) => {
	const cleanedStrings = strings.filter((string) => string);

	const matchingIncrementValues = cleanedStrings
		.filter((string) => string.startsWith(stringToMatch))
		.map((string) => string.split(stringToMatch)[1])
		.filter((string) => !Number.isNaN(Number(string)));

	// -1 to keep minimum value at 0 - sorts edge case when matching value ends with 0
	const biggestIncrement = (Math.max(-1, ...matchingIncrementValues) + 1);

	return `${stringToMatch}${biggestIncrement || ''}`;
};
