export const MOBILE_BLOCK_PADDING_X = 16;

export const DESKTOP_BLOCK_WIDTH = 1224;
export const MOBILE_BLOCK_WIDTH = 414;

export const MOBILE_ELEMENT_TOP_OFFSET = 16;
export const DESKTOP_ELEMENT_TOP_OFFSET = 50;

export const MOBILE_BUILDER_WIDTH = MOBILE_BLOCK_WIDTH - MOBILE_BLOCK_PADDING_X * 2;

export const DEFAULT_MOBILE_BLOCK_HEIGHT = 16;

export const MOBILE_RECALCULATE_DELAY = 2000;
